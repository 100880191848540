<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('users.roles') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
            <template v-if="roles.length > 0">
              <b-list-group>
                <b-list-group-item v-for="role in roles" :key="role.id" class="d-flex align-items-center" :to="{ name : 'EditUserRole', params: { roleId: role.id }}">
                  <b-avatar variant="primary" icon="BIconClipboard" class="mr-4" size="3rem"><BIconClipboard scale="1.5"/></b-avatar>
                  <h3 class="display-5">{{ role.name }}</h3>
                  <b-badge></b-badge>
                </b-list-group-item>
              </b-list-group>
            </template>
            <template v-else>
              <b-alert variant="warning" :show="noRoles">
                <p><BIconExclamationCircleFill /> {{ $t('users.no-roles')}}</p>
                <p><b-link :to="{ name : 'AddUserRole'}"> {{ $t('users.add-role') }} </b-link></p>
              </b-alert>
            </template>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BIconExclamationCircleFill, BIconClipboard } from "bootstrap-vue";

export default {
  name : "Roles",
  components: { NavBar, BIconExclamationCircleFill, BIconClipboard },
  data: function() {
    return {
      roles: [],
      showOverlay: true,
      noRoles: false
    }
  },
  created: function() {
    this.$http.get('/cms/api/users/roles/all', { headers: {Authorization: this.getAuthData().accessToken }}).then(r => {
      this.roles = r.data;
      if (this.roles.lenght > 0) { this.noRoles = true }
    }).catch(error => { this.handleError(error); })
    this.showOverlay = false;
  }
}
</script>

<style scoped>
  .b-overlay-wrap {min-height: 200px;}
</style>
